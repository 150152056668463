<template>
  <div>
    <top-menu></top-menu>
    <section id="container">
      <left-menu></left-menu>
      <section id="content">
        <section class="studybox_area">
          <div class="studybox_contact_btn"><img src="/image/common/top_ic_contact.png" alt="상담하기" /></div>
          <div class="studybox_h2_box">
            <h2>레벨 선택</h2>
          </div>
          <section class="study_myinfo_list_area" >
            <div class="mypage_scroll_content">
              <section class="study_main_box">
                <!-- content -->
                <!-- 레벨 선택 01 -->
                <div class="program_level_wrap01">
                  <ul>
                    <li>
                      <div class="program_level_box_left">
                        <p class="program_level_box_level">LEVEL</p>
                        <p class="program_level_box_num">1</p>
                      </div>
                      <div class="program_level_title">문장익히기 초급</div>
                      <div class="program_level_btn_right">
                        <button type="button" class="btn program_level_btn"
                          @click.prevent="$router.push({ name: 'program.level.class-per-week',params: { levelId: 1 } })"
                        >
                          <a href="#">선택</a>
                        </button>
                      </div>
                    </li>
                    <li>
                      <div class="program_level_box_left">
                        <p class="program_level_box_level">LEVEL</p>
                        <p class="program_level_box_num">2</p>
                      </div>
                      <div class="program_level_title">문장익히기 중급</div>
                      <div class="program_level_btn_right">
                        <button type="button" class="btn program_level_btn"
                          @click.prevent="$router.push({ name: 'program.level.class-per-week',params: { levelId: 2 } })"
                        >
                          <a href="#">선택</a>
                        </button>
                      </div>
                    </li>
                    <li>
                      <div class="program_level_box_left">
                        <p class="program_level_box_level">LEVEL</p>
                        <p class="program_level_box_num">3</p>
                      </div>
                      <div class="program_level_title">문장익히기 고급</div>
                      <div class="program_level_btn_right">
                        <button type="button" class="btn program_level_btn"
                          @click.prevent="$router.push({ name: 'program.level.class-per-week',params: { levelId: 3 } })"
                        >
                          <a href="#">선택</a>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- //레벨 선택 01 -->
                <!-- 레벨 선택 02 -->
                <div class="program_level_wrap02">
                  <ul>
                    <li>
                      <div class="program_level_box_left">
                        <p class="program_level_box_level">LEVEL</p>
                        <p class="program_level_box_num">4</p>
                      </div>
                      <div class="program_level_title">스토리만들기 초급</div>
                      <div class="program_level_btn_right">
                        <button type="button" class="btn program_level_btn02"
                          @click.prevent="$router.push({ name: 'program.level.class-per-week',params: { levelId: 4 } })"
                        >
                          <a href="#">선택</a>
                        </button>
                      </div>
                    </li>
                    <li>
                      <div class="program_level_box_left">
                        <p class="program_level_box_level">LEVEL</p>
                        <p class="program_level_box_num">5</p>
                      </div>
                      <div class="program_level_title">스토리만들기 중급</div>
                      <div class="program_level_btn_right">
                        <button type="button" class="btn program_level_btn02"
                          @click.prevent="$router.push({ name: 'program.level.class-per-week',params: { levelId: 5 } })"
                        >
                          <a href="#">선택</a>
                        </button>
                      </div>
                    </li>
                    <li>
                      <div class="program_level_box_left">
                        <p class="program_level_box_level">LEVEL</p>
                        <p class="program_level_box_num">6</p>
                      </div>
                      <div class="program_level_title">스토리만들기 고급</div>
                      <div class="program_level_btn_right">
                        <button type="button" class="btn program_level_btn02"
                          @click.prevent="$router.push({ name: 'program.level.class-per-week',params: { levelId: 6 } })"
                        >
                          <a href="#">선택</a>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- //레벨 선택 02 -->
                <!-- 레벨 선택 03 -->
                <div class="program_level_wrap03">
                  <ul>
                    <li>
                      <div class="program_level_box_left03">
                        <p class="program_level_box_level">LEVEL</p>
                        <p class="program_level_box_num03">7</p>
                      </div>
                      <div class="program_level_title03">Summary 훈련을 통한 논리학습 초급</div>
                      <div class="program_level_btn_right">
                        <button type="button" class="btn program_level_btn02"
                          @click.prevent="$router.push({ name: 'program.level.class-per-week',params: { levelId: 7 } })"
                        >
                          <a href="#">선택</a>
                        </button>
                      </div>
                    </li>
                    <li>
                      <div class="program_level_box_left03">
                        <p class="program_level_box_level">LEVEL</p>
                        <p class="program_level_box_num03">8</p>
                      </div>
                      <div class="program_level_title03">Summary 훈련을 통한 논리학습 중급</div>
                      <div class="program_level_btn_right">
                        <button type="button" class="btn program_level_btn02"
                          @click.prevent="$router.push({ name: 'program.level.class-per-week',params: { levelId: 8 } })"
                        >
                          <a href="#">선택</a>
                        </button>
                      </div>
                    </li>
                    <li>
                      <div class="program_level_box_left03">
                        <p class="program_level_box_level">LEVEL</p>
                        <p class="program_level_box_num03">9</p>
                      </div>
                      <div class="program_level_title03">Summary 훈련을 통한 논리학습 고급</div>
                      <div class="program_level_btn_right">
                        <button type="button" class="btn program_level_btn02"
                          @click.prevent="$router.push({ name: 'program.level.class-per-week',params: { levelId: 9 } })"
                        >
                          <a href="#">선택</a>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- //레벨 선택 03 -->
                <!-- 레벨 선택 04 -->
                <div class="program_level_wrap04">
                  <ul>
                    <li>
                      <div class="program_level_box_left03">
                        <p class="program_level_box_level">LEVEL</p>
                        <p class="program_level_box_num03">10</p>
                      </div>
                      <div class="program_level_title03">미국 영국 등 해외 유학 준비 및 고급 영어 학습</div>
                      <div class="program_level_btn_right">
                        <button type="button" class="btn program_level_btn02"
                          @click.prevent="$router.push({ name: 'program.level.class-per-week',params: { levelId: 10 } })"
                        >
                          <a href="#">선택</a>
                        </button>
                      </div>
                    </li>
                  </ul>
                </div>
                <!-- //레벨 선택 04 -->
                <!-- //content -->
              </section>
            </div>
          </section>
        </section>
        <footer-navigation :back="{ name: 'program' }"></footer-navigation>
        <content-footer></content-footer>
      </section>
    </section>
    <main-footer></main-footer>
  </div>
</template>

<script>
import LeftMenu from '@/layouts/components/Menu/LeftMenu'
import TopMenu from '@/layouts/components/Menu/TopMenu'
import FooterNavigation from '@/layouts/components/FooterNavigation'
import MainFooter from '@/layouts/components/Footer'
import ContentFooter from '@/layouts/components/ContentFooter'

export default {
  name: 'Program.Level',
  components: {
    LeftMenu,
    TopMenu,
    FooterNavigation,
    MainFooter,
    ContentFooter
  }
}
</script>

<style scoped>

</style>
